import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    let count = this.counter()
    let show_sus = localStorage.getItem("aapl.io.show_sus");

    if (count > 2) {
      if (show_sus) {
        this.dismiss()
      }
      else {
        this.show()
      }
    }
  }

  dismiss(e) {
    e.preventDefault
    const sus = document.getElementById('sus')
    sus.style.display = "none";
    localStorage.setItem("aapl.io.show_sus", false);
  }

  show() {
    const sus = document.getElementById('sus')
    sus.style.display = "block";
  }

  counter() {
    let visits = 1
    let storage = localStorage.getItem("aapl.io.visits")
    if (storage) {
      visits = parseInt(storage) + 1;
    }
    localStorage.setItem("aapl.io.visits", visits);
    return visits
  }
}
