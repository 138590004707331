import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    if (localStorage.getItem("aapl.io.cookieDialog")) {
      this.dismiss()
    }
  }

  dismiss() {
    const notification = document.getElementById('cookies')
    notification.style.display = "none";
    localStorage.setItem("aapl.io.cookieDialog", false);
  }
}
