import { Controller } from "stimulus"

export default class extends Controller {
  onCurrentController() {
    let activeLinks = this.data.get("active-on");
    return activeLinks == active_param;
  }

  connect() {
    if (this.onCurrentController()) {
      this.element.classList.add("bg-indigo-800", "text-white", "rounded");
      this.element.classList.remove("hover:text-indigo-800");
    } else {
      this.element.classList.add("hover:text-indigo-800");
      this.element.classList.remove("bg-indigo-800", "text-white", "rounded");
    }
  }
}
